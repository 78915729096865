import '../styles/Footer.css'

const Footer = () => {
  return (
    <footer className="Foot">
      <p>Jon / Web Dev © 2022</p>
    </footer>
  )
}

export default Footer
